var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Help" } }),
      _c("gov-back-link", { attrs: { to: { name: "dashboard" } } }, [
        _vm._v("Back to dashboard")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "full" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Help")
                  ]),
                  _c("gov-body", [
                    _vm._v(
                      'Welcome to the help section. Please follow the links below to our\n          series of "how to" guides on YouTube.'
                    )
                  ]),
                  _vm._l(_vm.pairedVideos, function(videoPair, i) {
                    return [
                      _c(
                        "gov-grid-row",
                        { key: "video-pair-" + i },
                        _vm._l(videoPair, function(video, j) {
                          return _c(
                            "gov-grid-column",
                            { key: "video-" + j, attrs: { width: "one-half" } },
                            [
                              _c("ck-video-iframe", {
                                attrs: {
                                  height: video.height,
                                  width: video.width,
                                  src: video.src,
                                  title: video.title
                                }
                              }),
                              _c("gov-heading", { attrs: { size: "m" } }, [
                                _vm._v(_vm._s(video.title))
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }